<h1>Right to Know, Correct & Delete Form</h1>

<div *ngIf="!submissionSuccessful">
  <p>By using this form, you may elect to exercise your privacy rights as a California resident. You may also submit a request to exercise these rights by calling us at 1-855-275-5787 or emailing us at privacyinquiries@gms.com.</p>

  <p><b>Instructions:</b> Please complete the required fields below. Information collected on this form will be used and retained only to process your request.</p>
</div>


<div *ngIf="submissionSuccessful">
  <p>Form submission successful. Thank you!</p>
</div>

<form (ngSubmit)="submitForm()" [formGroup]="rtkdForm" class="mat-typography" #captchaProtectedForm="ngForm" *ngIf="!submissionSuccessful">
  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('firstName')}">
    <span class="show-for-sr">First Name</span>
    <input matInput type="text" formControlName="firstName" name="firstName" required placeholder="First Name *">
  </label>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('middleName')}">
    <span class="show-for-sr">Middle Name</span>
    <input matInput type="text" formControlName="middleName" name="middleName" placeholder="Middle Name">
  </label>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('lastName')}">
    <span class="show-for-sr">Last Name</span>
    <input matInput type="text" formControlName="lastName" name="lastName" required placeholder="Last Name *">
  </label>

  <mat-form-field class="mat-remove-spacing dropdown-list" [hideRequiredMarker]="true" [ngClass]="{'is-invalid': isInvalid('state')}">
    <mat-select formControlName="state" class="dropdown" name="state" required>
      <mat-option value="CA">I hereby certify that I am a California Resident</mat-option>
      <mat-option value="other">Other State</mat-option>
    </mat-select>
    <div class="warning" *ngIf="rtkdForm.get('state').value === 'other'">
      Sorry, this request is not yet available in your jurisdiction.
    </div>
    <mat-label *ngIf="rtkdForm.get('state').value === ''">State of Residence *</mat-label>
  </mat-form-field>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('email')}">
    <span class="show-for-sr">E-mail Address</span>
    <input matInput type="email" formControlName="email" name="email" required placeholder="E-mail Address *">
  </label>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('phoneNumber')}">
    <span class="show-for-sr">Phone Number</span>
    <input matInput type="tel" formControlName="phoneNumber" name="phoneNumber" [placeholder]="phoneNumberPlaceholder">
  </label>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('address')}">
    <span class="show-for-sr">Physical Address</span>
    <input matInput type="text" formControlName="address" name="address" [placeholder]="addressPlaceholder">
  </label>

  <label class="text-input" [ngClass]="{'is-invalid': isInvalid('accountNumber')}">
    <span class="show-for-sr">Account Number<span class="optional">(optional)</span></span>
    <input matInput type="text" formControlName="accountNumber" name="accountNumber" placeholder="Account Number">
  </label>

  <!-- Other form fields -->

  <p>How would you like us to contact you in regards to this request? </p>

  <label class="checkbox-group" [ngClass]="{'is-invalid': isInvalid('contactPreference')}">
    <span class="show-for-sr">Contact Preference</span>
    <div formArrayName="contactPreference">
      <mat-checkbox class="checkbox-option" formControlName="email" name="contactPreference" value="email">Email address</mat-checkbox>
      <mat-checkbox class="checkbox-option" formControlName="phone" name="contactPreference" value="phone">Phone number</mat-checkbox>
      <mat-checkbox class="checkbox-option" formControlName="letter" name="contactPreference" value="letter">By letter to your address listed above</mat-checkbox>
    </div>
  </label>

  <p>
    If you provided an email address to us as part of submitting this request, you consent to receive electronic communications (email) from us regarding your request.
  </p>

  <p>I am a:</p>

  <label class="radio-group" [ngClass]="{'is-invalid': isInvalid('requestTypes')}">
    <mat-radio-group formControlName="userType" name="userType" required (click)="$event.preventDefault()">
      <mat-radio-button (click)="updateUserType('customer')" [checked]="rtkdForm.get('userType').value === 'customer'">Customer</mat-radio-button>
      <mat-radio-button (click)="updateUserType('marketing')" [checked]="rtkdForm.get('userType').value === 'marketing'">Marketing Recipient</mat-radio-button>
      <mat-radio-button (click)="updateUserType('employee')" [checked]="rtkdForm.get('userType').value === 'employee'">Current/Former Employee</mat-radio-button>
      <mat-radio-button (click)="updateUserType('applicant')" [checked]="rtkdForm.get('userType').value === 'applicant'">Current/Former Applicant</mat-radio-button>
      <mat-radio-button (click)="updateUserType('agent')" [checked]="rtkdForm.get('userType').value === 'agent'">Authorized Agent submitting a request on behalf of another</mat-radio-button>
      <mat-radio-button (click)="updateUserType('other')" [checked]="rtkdForm.get('userType').value === 'other'">Other</mat-radio-button>
    </mat-radio-group>
  </label>
  <label class="text-input" *ngIf="rtkdForm.get('userType').value === 'other'">
    <input matInput class="text" formControlName="otherUserType" placeholder="Specify other user type">
  </label>

  <p>Please select the type of request you wish to make:</p>

  <label class="checkbox-group" [ngClass]="{'is-invalid': isInvalid('requestTypes')}">
    <div formArrayName="requestTypes">
      <mat-checkbox class="checkbox-option" formControlName="know" name="requestTypes" value="know"
        (change)="toggleRequestSection('know', rtkdForm.get('requestTypes.know').value)">
        Request to know about personal information collected
      </mat-checkbox>
      <mat-checkbox class="checkbox-option" formControlName="delete" name="requestTypes" value="delete"
        (change)="toggleRequestSection('delete', rtkdForm.get('requestTypes.delete').value)">
        Request deletion of personal information that we may have collected from you
      </mat-checkbox>
      <mat-checkbox class="checkbox-option" formControlName="correct" name="requestTypes" value="correct"
        (change)="toggleRequestSection('correct', rtkdForm.get('requestTypes.correct').value)">
        Request to correct personal information
      </mat-checkbox>
    </div>
  </label>

  <re-captcha [siteKey]="recaptchaSiteKey" [formControlName]="'captchaResponse'" name="captcha" required></re-captcha>

  <div [hidden]="displayrecaptchaMessage" class="error">Please fill out the reCAPTCHA.</div>

  <!-- Next button -->
  <button *ngIf="!isDetailsValid" mat-raised-button color="primary" (click)="next($event)">Next</button>

  <!-- Additional text and inputs -->
  <div *ngIf="showRequestToKnowSection" [formGroup]="requestToKnowForm">
    <h2>Please let us know what information you are requesting:</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="categoriesCollected">The categories of personal information we have collected about you.</mat-checkbox>
      <mat-checkbox formControlName="categoriesSources">The categories of sources from which we have collected personal information about you.</mat-checkbox>
      <mat-checkbox formControlName="purposeCollecting">The business or commercial purpose for collecting personal information about you.</mat-checkbox>
      <mat-checkbox formControlName="disclosedThirdParties">The categories of third parties to whom the personal information was disclosed for a business purpose.</mat-checkbox>
      <mat-checkbox formControlName="specificPieces" (change)="toggleSpecificPieces($event)">Specific pieces of personal information that we have collected about you relating to:</mat-checkbox>
      <textarea matInput formControlName="specificPiecesDescription" placeholder="Please describe the information you are requesting in 180 words or less" [hidden]="!requestToKnowForm.get('specificPieces').value"></textarea>
    </div>

    <p>We will comply with your request to know your personal information unless the personal information is outside the scope of California’s privacy laws, is otherwise exempt from disclosure, or we cannot confirm your identity. Note that California law prohibits us from disclosing certain sensitive types of information including financial account numbers, an account password, or security questions and answers.</p>

    <h2>Please confirm your identity</h2>
    <p>We will contact you by email to collect the necessary information.</p>

    <p>(Note: For requests to know the categories or purposes (boxes 1-4 above), require at least two pieces of information to be submitted. For requests to know specific pieces of information, require at least three pieces of information to be submitted for confirmation.)</p>

    <h2>Delivery of Specific Pieces of Information</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="deliveryPaper" (change)="toggleDeliveryCheckbox('deliveryPaper')">Paper</mat-checkbox>
      <mat-checkbox formControlName="deliveryElectronic" (change)="toggleDeliveryCheckbox('deliveryElectronic')">Electronic</mat-checkbox>
    </div>
  </div>

  <!-- Additional text and inputs for Request to Delete -->
  <div *ngIf="showRequestToDeleteSection" [formGroup]="requestToDeleteForm">
    <h2>Second screen if Request to Delete is requested:</h2>
    <p>When you submit a request to delete, we will delete personal information that we have collected about you consistent with your request, unless:</p>
    <ol>
      <li>We must or are permitted to keep the information by law or regulation.</li>
      <li>Your request to delete personal information is outside the scope of California’s privacy laws.</li>
      <li>We cannot confirm your identity.</li>
    </ol>
    <p>We will maintain a record of your request to delete.</p>

    <h2>Please let us know what personal information you want us to delete:</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="deleteAll">All personal information that we have collected about you, if the information is in scope.</mat-checkbox>
      <mat-checkbox formControlName="deleteFinancial">All my financial information</mat-checkbox>
      <mat-checkbox formControlName="deleteBrowsing">All my web browsing information</mat-checkbox>
      <mat-checkbox formControlName="deleteCommercial">All my commercial information</mat-checkbox>
      <mat-checkbox formControlName="deleteOther" (change)="toggleDeleteOther($event)">Other</mat-checkbox>
      <textarea matInput formControlName="deleteOtherDescription" placeholder="Please provide additional details" [hidden]="!requestToDeleteForm.get('deleteOther').value"></textarea>        
    </div>
  </div>

  <div *ngIf="showRequestToDeleteSection || showRequestToKnowSection">
    <h2>Please confirm your identity</h2>
    <p>We will contact you by email to collect the necessary information.</p>
  
    <h2>Submit a request on behalf of someone else</h2>
    <p>If you are requesting personal information on behalf of someone else, please upload your authorization letter here. We may require the person to verify their identity with us directly and to verify that you have been authorized to submit a request on their behalf. If we verify that you are authorized to know the requested personal information, we will contact you to collect additional information.</p>
  
    <label class="upload-button">
      <input type="file" (change)="handleFileUpload($event)">
      Upload Authorization
      <span *ngIf="authorizationFileName"> : <strong>{{ authorizationFileName }}</strong></span>
    </label>

    <h2>Declaration under penalty of perjury</h2>
    <p>By submitting this form, you agree, on penalty of perjury or other criminal offense under applicable law, that either you are the individual about whom this request for personal information relates, or that you are authorized by that individual to make this request on their behalf.</p>
  </div>

  <div *ngIf="showSubmitButton">
    <button mat-raised-button color="primary" type="submit" [disabled]="rtkdForm.invalid || requestToDeleteForm.invalid || requestToKnowForm.invalid">Submit</button>
  </div>
</form>
