import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './request-interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

// Angular material upgrade
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

import { NguCarouselModule } from '@ngu/carousel';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';

// Page components
import { BasePageComponent } from './pages/base-page/base-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FlipbookPageComponent } from './pages/flipbook-page/flipbook-page.component';
import { GenericContentPageComponent } from './pages/generic-content-page/generic-content-page.component';
import { ProductCategoryPageComponent } from './pages/product-category-page/product-category-page.component';
import { SearchResultsPageComponent } from './pages/search-results-page/search-results-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { SubsidiaryPageComponent } from './pages/subsidiary-page/subsidiary-page.component';
import { VendorPageComponent } from './pages/vendor-page/vendor-page.component';
// Directives and pipes
import { RunScriptsDirective } from '../run-scripts.directive';
import { SearchPipe } from './directives-and-pipes/search.pipe';
import { ProtocolPipe } from './directives-and-pipes/protocol.pipe';
import { SafePipe } from './directives-and-pipes/safe.pipe';
import { AssetPipe } from './directives-and-pipes/asset.pipe';
import { FullStateNamePipe } from "./directives-and-pipes/fullNameState.pipe";
import { ShortStateNamePipe } from "./directives-and-pipes/shortNameState.pipe";
import { LinkRewriterPipe } from './directives-and-pipes/link-rewriter.pipe';
import { ImagePreloadDirective } from './directives-and-pipes/image-preload.directive';
import { EncodeUriComponentPipe } from './directives-and-pipes/encode-uri-component.pipe';
import { StatesPipe } from './directives-and-pipes/states.pipe';
// Components
import { GlobalHeroComponent } from './global-components/global-hero/global-hero.component';
import { ImageHeroComponent } from './global-components/image-hero/image-hero.component';
import { MainNavComponent } from './global-components/main-nav/main-nav.component';
import { BreadcrumbComponent } from './global-components/breadcrumb/breadcrumb.component';
import { LogoGridComponent } from './global-components/logo-grid/logo-grid.component';
import { ContentTeaserComponent } from './global-components/content-teaser/content-teaser.component';
import { ContentColumnsComponent } from './global-components/content-columns/content-columns.component';
import { ContentSectionComponentComponent } from './global-components/content-section-component/content-section-component.component';
import { BulletedContentSectionComponent } from './global-components/bulleted-content-section/bulleted-content-section.component';
import { ImageCarouselComponent } from './global-components/image-carousel/image-carousel.component';
import { ContentTilesComponent } from './global-components/content-tiles/content-tiles.component';
import { ImagePullQuoteComponent } from './global-components/image-pull-quote/image-pull-quote.component';
import { FeaturedContentComponent } from './global-components/featured-content/featured-content.component';
import { DetailedContentImageComponent } from './global-components/detailed-content-image/detailed-content-image.component';
import { ContentSectionVideoComponent } from './global-components/content-section-video/content-section-video.component';
import { PageBreakComponent } from './global-components/page-break/page-break.component';
import { PageSpacingComponent } from './global-components/page-spacing/page-spacing.component';
import { PageNegativeSpacingComponent } from './global-components/page-negative-spacing/page-negative-spacing.component';
import { VendorListComponentComponent } from './vendor-page-components/vendor-list-component/vendor-list-component.component';
import { LogoListComponent } from './logo-page-components/logo-list-component/logo-list.component';
import { SubsidiaryHeadingComponent } from './subsidiary-page-components/subsidiary-heading/subsidiary-heading.component';
import { SubsidiaryLocationsComponent } from './subsidiary-page-components/subsidiary-locations/subsidiary-locations.component';
import { ProductTilesComponent } from './product-category-page-components/product-tiles/product-tiles.component';
import { ServicesTilesComponentsComponent } from './services-page-components/services-tiles-components/services-tiles-components.component';
import { FindAYardComponent } from './pages/find-a-yard/find-a-yard.component';
import { FindAYardSearchComponent } from './find-a-yard-components/find-a-yard-search/find-a-yard-search.component';
import { FindAYardResultsComponent } from './find-a-yard-components/find-a-yard-results/find-a-yard-results.component';
import { FindAYardMapComponent } from './find-a-yard-components/find-a-yard-map/find-a-yard-map.component';
import { googleMapsApiKey } from './app.constants';
import { GlobalFooterComponent } from './global-components/global-footer/global-footer.component';
import { GlobalHeaderComponent } from './global-components/global-header/global-header.component';
import { PageHeadingComponent } from './global-components/page-heading/page-heading.component';
import { ConfigComponent } from './find-a-yard-components/config/config.component';
import { SearchConfigComponent } from './global-components/search-results-list/search-config/search-config.component';
import { VideoHeroComponent } from './global-components/video-hero/video-hero.component';
import { ContentWellComponent } from './global-components/content-well/content-well.component';
import { SeoComponent } from './global-components/seo/seo.component';
import { InternalSearchComponent } from './global-components/internal-search/internal-search.component';
import { GlobalSearchComponent } from './global-components/global-search/global-search.component';
import { SearchResultsListComponent } from './global-components/search-results-list/search-results-list.component';
import { BackToTopComponent } from './global-components/back-to-top/back-to-top.component';
import { CookieInfoComponent } from './global-components/cookie-info/cookie-info.component';
import { RtkdFormComponent } from './global-components/rtkd-form/rtkd-form.component';

// Services
import { CookieService } from 'ngx-cookie-service';
import { GoogleLocationService } from './services/google-location.service';
import { LocationsService } from './services/locations.service';
import { SearchService } from './services/search.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RtkdFormService } from './services/rtkd-form.service';

/*** Upgrade ***/
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { FallbackComponent } from './fallback-component/fallback.component';
import { environment } from '../environments/environment';
import { IndexComponent, ENDPOINT } from './index/index.component';
import { ParseUrlPipe } from './directives-and-pipes/parse-url.pipe';
import { IsExternalLinkPipe } from './directives-and-pipes/is-external-link.pipe';
import { IsInternalLinkPipe } from './directives-and-pipes/is-internal-link.pipe';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,

    // Bloomreach components
    BasePageComponent,

    // GMS Components
    BackToTopComponent,
    BulletedContentSectionComponent,
    ConfigComponent,
    ContentSectionComponentComponent,
    ContentSectionVideoComponent,
    ContentTeaserComponent,
    ContentColumnsComponent,
    ContentTilesComponent,
    ContentWellComponent,
    CookieInfoComponent,
    DetailedContentImageComponent,
    FeaturedContentComponent,
    FlipbookPageComponent,
    GlobalFooterComponent,
    GlobalHeaderComponent,
    GlobalHeroComponent,
    ImageCarouselComponent,
    ImageHeroComponent,
    ImagePullQuoteComponent,
    InternalSearchComponent,
    LogoGridComponent,
    LogoListComponent,
    PageHeadingComponent,
    PageNegativeSpacingComponent,
    PageSpacingComponent,
    ProductTilesComponent,
    SearchConfigComponent,
    SeoComponent,
    ServicesTilesComponentsComponent,
    SubsidiaryHeadingComponent,
    SubsidiaryLocationsComponent,
    VendorListComponentComponent,
    VideoHeroComponent,

    // Other components
    BreadcrumbComponent,
    FindAYardComponent,
    FindAYardMapComponent,
    FindAYardResultsComponent,
    FindAYardSearchComponent,
    GenericContentPageComponent,
    GlobalSearchComponent,
    HomePageComponent,
    MainNavComponent,
    PageBreakComponent,
    ProductCategoryPageComponent,
    SearchResultsListComponent,
    SearchResultsPageComponent,
    ServicesPageComponent,
    SubsidiaryPageComponent,
    SubsidiaryPageComponent,
    VendorPageComponent,

    // Pipes and Directives
    AssetPipe,
    EncodeUriComponentPipe,
    FullStateNamePipe,
    LinkRewriterPipe,
    ProtocolPipe,
    SearchPipe,
    SafePipe,
    ShortStateNamePipe,
    StatesPipe,
    ImagePreloadDirective,
    RunScriptsDirective,

    // Upgrade
    IndexComponent,
    FallbackComponent,
    ParseUrlPipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    RtkdFormComponent,
  ],
  imports: [
    // Add .withServerTransition() to support Universal rendering.
    // The application ID can be any identifier which is unique on
    // the page.
    BrowserModule.withServerTransition({appId: 'gms-app'}),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OverlayModule,
    MatButtonModule,
    MatCheckboxModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    FormsModule,
    HttpClientModule,
    NguCarouselModule,
    // BloomreachExperienceNgSdkModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxPageScrollCoreModule.forRoot({duration: 300}),
    AgmCoreModule.forRoot({
      apiKey: googleMapsApiKey,
      libraries: ['geometry']
    }),
    BrSdkModule,
    NgxWebstorageModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    LocationsService,
    SearchService,
    GoogleLocationService,
    CookieService,
    RtkdFormService,
    FullStateNamePipe,
    ShortStateNamePipe,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {float: 'always'}},
    // {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    { provide: ENDPOINT, useValue: environment.endpoint },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    BackToTopComponent,
    BulletedContentSectionComponent,
    ConfigComponent,
    ContentSectionComponentComponent,
    ContentSectionVideoComponent,
    ContentColumnsComponent,
    ContentTilesComponent,
    ContentWellComponent,
    CookieInfoComponent,
    DetailedContentImageComponent,
    FeaturedContentComponent,
    FlipbookPageComponent,
    GlobalFooterComponent,
    GlobalHeaderComponent,
    GlobalHeroComponent,
    ImageCarouselComponent,
    ImageHeroComponent,
    ImagePullQuoteComponent,
    InternalSearchComponent,
    LogoGridComponent,
    LogoListComponent,
    PageBreakComponent,
    PageHeadingComponent,
    PageNegativeSpacingComponent,
    PageSpacingComponent,
    ProductTilesComponent,
    SearchConfigComponent,
    SeoComponent,
    ServicesTilesComponentsComponent,
    SubsidiaryHeadingComponent,
    SubsidiaryLocationsComponent,
    VendorListComponentComponent,
    VideoHeroComponent,
    FindAYardSearchComponent,
    FallbackComponent,
    IndexComponent,
  ]
})

export class AppModule { }
