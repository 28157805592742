import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl, ValidatorFn, Validator, ValidationErrors } from '@angular/forms';
import { RtkdFormService } from '../../services/rtkd-form.service';

import { recaptchaSiteKeyConst } from '../../app.constants';

@Component({
  selector: 'app-rtkd-form',
  templateUrl: './rtkd-form.component.html',
  styleUrls: ['./rtkd-form.component.scss']
})
export class RtkdFormComponent implements OnInit {
  rtkdForm: FormGroup;
  requestToKnowForm: FormGroup;
  requestToDeleteForm: FormGroup;

  showRequestToKnowSection: boolean = false;
  showRequestToDeleteSection: boolean = false;
  isDetailsValid: boolean = false;
  showSubmitButton: boolean = false;

  recaptchaSiteKey: string = recaptchaSiteKeyConst;
  displayrecaptchaMessage: boolean = true;

  authorizationFileName: string;

  phoneNumberPlaceholder = '';
  phoneNumberPlaceholderDefault = "Phone Number";
  addressPlaceholder = '';
  addressPlaceholderDefault = "Physical Address";
  requiredFlag = "*";

  submissionSuccessful = false;

  constructor(private formBuilder: FormBuilder, private rtkdFormService: RtkdFormService) {
    // Pre completed
    this.rtkdForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      state: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      address: ['', Validators.required],
      accountNumber: [''],
      contactPreference: this.formBuilder.group({
        email: [false, Validators.required],
        phone: [false],
        letter: [false]
      }),
      consentEmail: [false],
      requestTypes: this.formBuilder.group({
        know: [false],
        delete: [false],
        correct: [false]
      }),
      userType: ['', Validators.required],
      otherUserType: [''],
      captchaResponse: ['', Validators.required],
      uploadAuthorization: ['']
    }, { validator: this.stateFormValidator });

    // Subscribe to changes in the 'phone' checkbox value
    this.rtkdForm.get('contactPreference.phone').valueChanges.subscribe((value) => {
      if (value) {
        this.rtkdForm.get('phoneNumber').setValidators(Validators.required);
        this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault + " " + this.requiredFlag;
      } else {
        this.rtkdForm.get('phoneNumber').clearValidators();
        this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault;
      }
      // Trigger revalidation of the 'phoneNumber' control
      this.rtkdForm.get('phoneNumber').updateValueAndValidity();
    });

    this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault;

    this.rtkdForm.get('state').valueChanges.subscribe((state: string) => {
      if (state === 'other') {
        this.rtkdForm.get('address').setValidators(Validators.required);
        this.addressPlaceholder = this.addressPlaceholderDefault + ' ' + this.requiredFlag;
      } else {
        this.rtkdForm.get('address').clearValidators();
        this.addressPlaceholder = this.addressPlaceholderDefault;
      }
      this.rtkdForm.get('address').updateValueAndValidity();
    });

    this.rtkdForm.get('contactPreference.letter').valueChanges.subscribe((value) => {
      if (value) {
        this.rtkdForm.get('address').setValidators(Validators.required);
        this.addressPlaceholder = this.addressPlaceholderDefault + ' ' + this.requiredFlag;
      } else {
        this.rtkdForm.get('address').clearValidators();
        this.addressPlaceholder = this.addressPlaceholderDefault;
      }
      this.rtkdForm.get('address').updateValueAndValidity();
    });

    this.addressPlaceholder = this.addressPlaceholderDefault;
  }

  ngOnInit() {
        // Initialize the form group for Request to Know section
    this.requestToKnowForm = this.formBuilder.group({
      categoriesCollected: [false],
      categoriesSources: [false],
      purposeCollecting: [false],
      disclosedThirdParties: [false],
      specificPieces: [false],
      specificPiecesDescription: [''],
      deliveryPaper: [false],
      deliveryElectronic: [false],
    });

    this.requestToDeleteForm = this.formBuilder.group({
      deleteAll: [false],
      deleteFinancial: [false],
      deleteBrowsing: [false],
      deleteCommercial: [false],
      deleteOther: [false],
      deleteOtherDescription: [''],
    });
  }

  next(event): void {
    event.preventDefault();
  
    if (!this.rtkdForm.get('captchaResponse').touched || this.rtkdForm.get('captchaResponse').valid) {
      this.displayrecaptchaMessage = true;

      // Trigger form control validation and mark all fields as touched
      this.markAllFieldsAsTouched();
  
      if (this.rtkdForm.valid) {
        // Check if the form is valid up to the requestTypes field
        if (this.rtkdForm.get('requestTypes').valid) {
          const requestTypesArray = this.rtkdForm.get('requestTypes') as FormArray;
  
          // Show the Request to Know section if 'know' option is selected
          if (this.checkRequestTypeSelected(requestTypesArray, 'know')) {
            this.showRequestToKnowSection = true;
          } else {
            this.showRequestToKnowSection = false;
          }
  
          // Show the Request to Delete section if 'delete' option is selected
          if (this.checkRequestTypeSelected(requestTypesArray, 'delete')) {
            this.showRequestToDeleteSection = true;
          } else {
            this.showRequestToDeleteSection = false;
          }
  
          // Set the flag to show the Submit button
          this.showSubmitButton = true;
        }
      }
    } else {
      this.displayrecaptchaMessage = false;
    }
  
    // Update the flag to indicate if the form is valid
    this.isDetailsValid = this.rtkdForm.valid;
  }

  // Custom form validator function
  stateFormValidator(formGroup: FormGroup): { [key: string]: any } | null {
    const stateControl = formGroup.get('state');

    if (stateControl.value === 'other') {
      return { invalidState: true };
    }

    return null;
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.rtkdForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  private checkRequestTypeSelected(requestTypesArray: FormArray, requestType: string): boolean {
    for (const key of Object.keys(requestTypesArray.controls)) {
      const control = requestTypesArray.controls[key] as FormControl;
      if (key === requestType && control.value === true) {
        return true;
      }
    }
    return false;
  }

  toggleRequestSection(requestType: string, checked: boolean): void {
    if(this.showSubmitButton) {
      if (requestType === 'know') {
        this.showRequestToKnowSection = checked;
      } else if (requestType === 'delete') {
        this.showRequestToDeleteSection = checked;
      }
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.rtkdForm.get(controlName);
    return control.invalid && (control.touched || control.dirty);
  }

  updateUserType(userType: string) {
    const otherUserTypeControl = this.rtkdForm.get('otherUserType');
    if (userType !== 'other') {
      otherUserTypeControl.setValue('');
    }
    this.rtkdForm.get('userType').setValue(userType);
  }

  onCaptchaResolved(response: string): void {
    this.rtkdForm.get('captchaResponse').setValue(response);
  }

  logData(): void {
    // Form submission logic goes here
    console.log('Form submitted!');
    console.log('First Name:', this.rtkdForm.get('firstName').value);
    console.log('Middle Name:', this.rtkdForm.get('middleName').value);
    console.log('Last Name:', this.rtkdForm.get('lastName').value);
    console.log('State of Residence:', this.rtkdForm.get('state').value);
    console.log('E-mail Address:', this.rtkdForm.get('email').value);
    console.log('Phone Number:', this.rtkdForm.get('phoneNumber').value);
    console.log('Physical Address:', this.rtkdForm.get('address').value);
    console.log('Account Number:', this.rtkdForm.get('accountNumber').value);
    console.log('Contact Preference:', this.getFormControlValues('contactPreference'));
    console.log('User type:', this.rtkdForm.get('userType').value);
    console.log('Other user type:', this.rtkdForm.get('otherUserType').value);
    console.log('Consent to Email:', this.rtkdForm.get('consentEmail').value);
    console.log('Request Types:', this.getFormControlValues('requestTypes'));
    console.log('Upload File:', this.rtkdForm.get('uploadAuthorization').value);
  
    if (this.showRequestToKnowSection) {
      console.log('Categories Collected:', this.requestToKnowForm.get('categoriesCollected').value);
      console.log('Categories Sources:', this.requestToKnowForm.get('categoriesSources').value);
      console.log('Purpose Collecting:', this.requestToKnowForm.get('purposeCollecting').value);
      console.log('Disclosed Third Parties:', this.requestToKnowForm.get('disclosedThirdParties').value);
      console.log('Specific Pieces:', this.requestToKnowForm.get('specificPieces').value);
      console.log('Specific Pieces Description:', this.requestToKnowForm.get('specificPiecesDescription').value);
      console.log('Web-Based Account:', this.requestToKnowForm.get('webBasedAccount').value);
      console.log('Email Contact:', this.requestToKnowForm.get('emailContact').value);
      console.log('Delivery Paper:', this.requestToKnowForm.get('deliveryPaper').value);
      console.log('Delivery Electronic:', this.requestToKnowForm.get('deliveryElectronic').value);
    }
  
    if (this.showRequestToDeleteSection) {
      console.log('Delete All:', this.requestToDeleteForm.get('deleteAll').value);
      console.log('Delete Financial:', this.requestToDeleteForm.get('deleteFinancial').value);
      console.log('Delete Browsing:', this.requestToDeleteForm.get('deleteBrowsing').value);
      console.log('Delete Commercial:', this.requestToDeleteForm.get('deleteCommercial').value);
      console.log('Delete Other:', this.requestToDeleteForm.get('deleteOther').value);
      console.log('Web-Based Account:', this.requestToDeleteForm.get('webBasedAccount').value);
      console.log('Email Contact:', this.requestToDeleteForm.get('emailContact').value);
    }
  }

  toggleSpecificPieces(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    if (!checked) {
      this.rtkdForm.get('specificPiecesDescription').reset();
    }
  }
  
  toggleDeleteOther(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const deleteOtherControl = this.rtkdForm.get('deleteOther');
  
    if (checkbox.checked) {
      deleteOtherControl.setValue(true);
    } else {
      deleteOtherControl.setValue(false);
      this.rtkdForm.get('deleteOtherDescription').reset();
    }
  }
  
  toggleDeliveryCheckbox(checkboxName: string): void {
    const paperCheckbox = this.requestToKnowForm.get('deliveryPaper');
    const electronicCheckbox = this.requestToKnowForm.get('deliveryElectronic');
  
    if (checkboxName === 'deliveryPaper' && paperCheckbox.value) {
      electronicCheckbox.setValue(false);
    } else if (checkboxName === 'deliveryElectronic' && electronicCheckbox.value) {
      paperCheckbox.setValue(false);
    }
  }

  private getFormControlValues(controlName: string): any[] {
    const formArray = this.rtkdForm.get(controlName) as FormArray;
    const values = Object.entries(formArray.controls).map((control) => {
      if(control[1].value) {
        return control[0];
      } else {
        return false;
      }
    });
    return values;
  }

  handleFileUpload(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
  
    // Read the file content using FileReader or other methods
    const reader = new FileReader();
    reader.onload = (e) => {
      // Encode the file content as base64 string
      const base64String = (reader.result as string).split(',')[1];
  
      // Set the encoded base64 string as the value of the form control
      this.rtkdForm.patchValue({ uploadAuthorization: base64String });

      this.authorizationFileName = file.name;
    };
    reader.readAsDataURL(file);
  }

  submitForm(): void {
    // this.logData();
    const formData = {
      rtkdForm: this.rtkdForm.value,
      requestToDeleteForm: this.requestToDeleteForm.value,
      requestToKnowForm: this.requestToKnowForm.value
    };
    if (this.rtkdForm.valid) {
      // Call the service's submitForm method and pass the form data
      this.rtkdFormService.submitForm(formData).subscribe(
        response => {
          // Handle the response here
          // console.log('Form submission successful', response);
          // Reset the forms if needed
          this.rtkdForm.reset();
          this.requestToDeleteForm.reset();
          this.requestToKnowForm.reset();

          // Update the submission status to true
          this.submissionSuccessful = true;
        },
        error => {
          // Handle the error here
          console.error('Error submitting form', error);
        }
      );
    } else {
      console.log('Form is invalid. Please fill in all required fields.');
    }
  }
}
